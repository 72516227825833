<template>
  <b-row align-h="center">
    <b-col>
      <b-overlay>
        <b-card header-tag="header" footer-tag="footer">
          <template v-slot:header>
            <div class="h4">
              <svg width="22" height="22" viewBox="0 0 22 22" fill="none" class="mt-2 go-back"
                   xmlns="http://www.w3.org/2000/svg"
                   v-if="template.currentAction !== 'ADD_NEW_LIQUIDITY'"
                   @click="$router.push('/liquidity')"
                   clickable>
                <rect width="1.83333" height="12.8333" rx="0.916667" transform="matrix(0 -1 -1 0 17.4165 11.9165)"
                      fill="#1BC5BD"/>
                <path
                    d="M11.6483 15.8517C12.0063 16.2096 12.0063 16.79 11.6483 17.148C11.2904 17.506 10.71 17.506 10.352 17.148L4.85198 11.648C4.50495 11.301 4.49281 10.7422 4.82444 10.3804L9.86611 4.88042C10.2082 4.50723 10.7881 4.48202 11.1612 4.82411C11.5344 5.1662 11.5596 5.74606 11.2176 6.11925L6.76896 10.9723L11.6483 15.8517Z"
                    fill="#1BC5BD"/>
              </svg>
              {{ getActionTemplate.title }}
            </div>
            <b-tabs class="mt-3" align="center"
                    v-if="template.currentAction !== 'ADD_NEW_LIQUIDITY' && stepperUuid && !isStepExists(stepperUuid)"
                    v-model="selectedPoolTab"
                    lazy>
              <b-tab title="AddLiquidity"
                     :active=showAddLiquidity></b-tab>
              <b-tab title="Withdraw"
                     :active=showWithdraw></b-tab>
            </b-tabs>
          </template>
          <template v-if="!isStepExists(stepperUuid)">
            <template v-if="showAddLiquidity">
              <AddLiquidity
                  :id="template.currentAction"
                  :ref="template.currentAction"
                  @valid="updateValid"
              ></AddLiquidity>
            </template>
            <template v-if="showWithdraw">
              <Withdraw
                  :id="template.currentAction"
                  :ref="template.currentAction"
                  @valid="updateValid"
              ></Withdraw>
            </template>
            <div class="card-footer">
              <b-button block variant="primary" class="button-flat mt-3"
                        v-bind:disabled="!template.isValid"
                        v-on:click="depositConfirmationPopup">
                <template>{{ getActionTemplate.btnText }}</template>
              </b-button>
            </div>
            <div class="card-table"
                 v-if="$store.state.pools.providedPools.length > 0 && template.currentAction === 'ADD_NEW_LIQUIDITY'">
              <div class="row align-items-center justify-content-between">
                <div class="col card-table-title">Your liquidity pools</div>
                <div class="col card-table-link col-md-auto" v-on:click="goToDashboard()">All pools</div>
              </div>
              <b-table borderless
                       :fields="existingPoolsFields"
                       :items="$store.state.pools.providedPools"
                       v-if="$store.state.web3.networkId && $store.state.pools.providedPools.length > 0"
                       tbody-tr-class="hover-link"
                       @row-clicked="rowClickHandler">
                <template v-slot:cell(pool)="{item}">
                  <TokenPair :tFrom=item.pair[0]
                             :tTo=item.pair[1]
                  ></TokenPair>
                </template>
                <template v-slot:cell(ownedAssets)="{item}">
                <span v-b-tooltip.hover v-bind:title="getOwnedAssetsTooltip(item)"
                      v-b-tooltip="{customClass: 'dark-tooltip'}" clickable>
                  {{ getOwnedAssets(item) }}
                </span>
                </template>
                <template v-slot:cell(poolTokensOwned)="{item}">
                <span v-b-tooltip.hover v-bind:title="bNValue(item.pool_tokens_owned)"
                      v-b-tooltip="{customClass: 'dark-tooltip'}" clickable>
                  {{ mRound(item.pool_tokens_owned, 6) }}
                </span>
                </template>
                <template v-slot:cell(shareOfPool)="{item}">
                <span v-b-tooltip.hover v-bind:title="getShareOfPool(item) * 100"
                      v-b-tooltip="{customClass: 'dark-tooltip'}" clickable>
                   {{ mRound(getShareOfPool(item) * 100, 2) }} %
                </span>
                </template>
              </b-table>
            </div>
          </template>
          <template v-else-if="isStepExists(stepperUuid)">
            <Step :con-data="confirmationPopupData" @close="onCloseStepper"></Step>
            <StepInformer :steps="getStepsById(stepperUuid)" :stepperUuid="stepperUuid"></StepInformer>
          </template>
        </b-card>
      </b-overlay>
    </b-col>
    <ConfirmationModal :ref="'confirmation-modal'"
                       :id="'confirmation-modal'"
                       :con-data="confirmationPopupData"
                       @ok="onConfirmOk"
    ></ConfirmationModal>
  </b-row>
</template>

<script>

import {mapActions, mapGetters, mapMutations} from "vuex";
import {
  HTTP_GET_POOL_ID_BY_TOKEN_PAIR,
  HTTP_GET_PROVIDED_POOLS,
  RESET_POOLS_STATE,
  SET_POOLS_TOKEN_DATA
} from "@/store/modules/pools.module";
import {LIQUIDITY_ADD, WITHDRAW} from "@/store/modules/web3.module";
import {mathRound} from "@/helpers/utils";
import {GET_WALLET_BALANCES} from "@/store/modules/tokens.module";
import BigNumber from "bignumber.js";
import {DELETE_STEP_BY_ID, INIT_STEP} from "@/store/modules/stepper.module";
import {uuid} from 'vue-uuid';

export const ADD_NEW_LIQUIDITY = 'ADD_NEW_LIQUIDITY'
export const ADD_LIQUIDITY_TO_POOL = 'ADD_LIQUIDITY_TO_POOL'
export const WITHDRAW_FROM_POOL = 'WITHDRAW_FROM_POOL'

export default {
  name: "Liquidity",
  components: {
    Withdraw: () => import("@/components/liquidity/Withdraw"),
    ConfirmationModal: () => import("@/components/confirmation/ConfirmationModal"),
    AddLiquidity: () => import("@/components/liquidity/AddLiquidity"),
    TokenPair: () => import("@/components/form/TokenPair"),
    Step: () => import("@/components/stepper/Step"),
    StepInformer: () => import("@/components/stepper/StepInformer"),
  },
  data: function () {
    return {
      template: {
        currentAction: ADD_NEW_LIQUIDITY,
        actions: {
          ADD_NEW_LIQUIDITY: {
            title: 'Add liquidity',
            btnText: 'Add liquidity'
          },
          ADD_LIQUIDITY_TO_POOL: {
            title: '',
            btnText: 'Add liquidity'
          },
          WITHDRAW_FROM_POOL: {
            title: '',
            btnText: 'Withdraw'
          }
        },
        isValid: false,
      },
      selectedPoolTab: 0,
      confirmationPopupData: {},
      existingPoolsFields: [
        {key: 'pool', thClass: 'caption'},
        {key: 'ownedAssets', thClass: 'caption'},
        {key: 'poolTokensOwned', thClass: 'caption'},
        {key: 'shareOfPool', thClass: 'caption'}
      ],
      stepperUuid: uuid.v1(),
    }
  },
  watch: {
    '$route': {
      handler: async function (route) {
        this.updateValid(false)
        await this.resetPoolsState()
        await this.httpGetProvidedPools()
        if (route.name === 'liquidity') {
          this.setTemplate(ADD_NEW_LIQUIDITY)
        }
        if (route.name === 'liquidity-pool') {
          this.setTemplate(ADD_LIQUIDITY_TO_POOL)
        }
      },
      immediate: true
    },
    '$store.state.web3.networkId': {
      handler: async function (val) {
        if (val) {
          await this.httpGetProvidedPools()
        }
      },
    },
    selectedPoolTab(val) {
      this.setPoolsTokenData({dst: 'from', data: {amount: new BigNumber(0)}})
      this.setPoolsTokenData({dst: 'to', data: {amount: new BigNumber(0)}})

      if (val === 1) {
        this.setTemplate(WITHDRAW_FROM_POOL)
      } else {
        this.setTemplate(ADD_LIQUIDITY_TO_POOL)
      }
    }
  },
  methods: {
    depositConfirmationPopup() {
      this.assignConfirmationData({waiting: false, modalType: 'confirm'})
      this.confirmationPopupData = this.getConfirmationData
      if (this.confirmationPopupData) {
        this.$refs['confirmation-modal'].$refs['confirmation-modal'].show()
      }
    },
    async onCloseStepper() {
      this.deleteStepById(this.stepperUuid)
      switch (this.template.currentAction) {
        case ADD_NEW_LIQUIDITY:
        case ADD_LIQUIDITY_TO_POOL:
          await this.$router.push(`/liquidity`)
          break
        case WITHDRAW_FROM_POOL:
          try {
            await this.$router.push(`/liquidity`)
            this.$refs[this.template.currentAction].resetForm()
          } catch (e) {
            console.debug(e)
          }
          break
      }
      this.stepperUuid = uuid.v1()
    },
    onConfirmOk(op) {
      if (op) {
        this.initStep(this.stepperUuid)
        this.$refs['confirmation-modal'].$refs['confirmation-modal'].hide()
        this.doDeposit();
      }
    },
    async doDeposit() {
      this.assignConfirmationData({waiting: true, modalType: 'confirm'})
      const childForm = this.$refs[this.template.currentAction].form
      switch (this.template.currentAction) {
        case ADD_NEW_LIQUIDITY:
        case ADD_LIQUIDITY_TO_POOL:
          await this.liquidityAdd({stepUuid: this.stepperUuid}).then(async (tx) => {
            this.assignConfirmationData({
              etherscanURL: this.getEtherscanTxLink(tx.transactionHash),
              successMessage: 'Success!',
              waiting: false,
              modalType: 'success'
            })
            try {
              // await this.$router.push(`/liquidity`)
            } catch (e) {
              await this.resetPoolsState()
              await this.getWalletBalances()
              await this.httpGetProvidedPools()
            }

          }).catch((e) => {
            this.assignConfirmationData(
                {modalType: 'error', errorMessage: e.message, waiting: false})
          })
          break
        case WITHDRAW_FROM_POOL:
          childForm.stepUuid = this.stepperUuid
          await this.withdraw(childForm).then(async (tx) => {
            this.assignConfirmationData({
              etherscanURL: this.getEtherscanTxLink(tx.transactionHash),
              successMessage: 'Success!',
              waiting: false,
              modalType: 'success'
            })
            // await this.$router.push(`/liquidity`)
            // this.$refs[this.template.currentAction].resetForm()
          }).catch((e) => {
            this.assignConfirmationData(
                {modalType: 'error', errorMessage: e.message, waiting: false})
          })
      }
    },
    setTemplate(name) {
      switch (name) {
        case ADD_NEW_LIQUIDITY:
          this.template.currentAction = ADD_NEW_LIQUIDITY
          break
        case ADD_LIQUIDITY_TO_POOL:
          this.template.currentAction = ADD_LIQUIDITY_TO_POOL
          this.template.actions[this.template.currentAction].title = `
                  ${this.$store.state.pools.from.symbol}/${this.$store.state.pools.to.symbol} pool
                `
          break
        case WITHDRAW_FROM_POOL:
          this.template.currentAction = WITHDRAW_FROM_POOL
          this.template.actions[this.template.currentAction].title = `
                  ${this.$store.state.pools.from.symbol}/${this.$store.state.pools.to.symbol} pool
                `
          break
      }
    },
    updateValid(result) {
      this.template.isValid = result;
    },
    assignConfirmationData(data) {
      this.confirmationPopupData = Object.assign(this.confirmationPopupData, data)
    },
    getShareOfPool(item) {
      return +item.pool_tokens_owned / +item.total_pool_tokens
    },
    getOwnedAssets(item) {
      return `${mathRound(item.balances[item.pair[0]] * this.getShareOfPool(item), 6)
      + '/'
      + mathRound(item.balances[item.pair[1]] * this.getShareOfPool(item), 6)}`
    },
    getOwnedAssetsTooltip(item) {
      return `${item.balances[item.pair[0]] * this.getShareOfPool(item)
      + '/'
      + item.balances[item.pair[1]] * this.getShareOfPool(item)}`
    },
    rowClickHandler(record) {
      this.$router.push({name: 'liquidity-pool', query: {f: record.pair[0], t: record.pair[1]}})
    },
    mRound(val, decimals = 8) {
      return this.roundBigNumber(new BigNumber(val), decimals)
    },
    bNValue(value) {
      return new BigNumber(value).valueOf()
    },
    goToDashboard() {
      return this.$router.push('/dashboard/arbitrage')
    },
    roundBigNumber(value, decimals = 8) {
      const roundedValue = value.dp(decimals, BigNumber.ROUND_DOWN)
      return roundedValue.valueOf() > 0 ? roundedValue : new BigNumber(0)
    },
    ...mapMutations([SET_POOLS_TOKEN_DATA]),
    ...mapActions([
      HTTP_GET_PROVIDED_POOLS,
      LIQUIDITY_ADD,
      RESET_POOLS_STATE,
      GET_WALLET_BALANCES,
      HTTP_GET_POOL_ID_BY_TOKEN_PAIR,
      WITHDRAW,
      INIT_STEP,
      DELETE_STEP_BY_ID
    ])
  },
  computed: {
    getActionTemplate() {
      return this.template.actions[this.template.currentAction]
    },
    showAddLiquidity() {
      return this.template.currentAction === ADD_NEW_LIQUIDITY
          || this.template.currentAction === ADD_LIQUIDITY_TO_POOL
    },
    showWithdraw() {
      return this.template.currentAction === WITHDRAW_FROM_POOL
    },
    getConfirmationData() {
      let childForm = this.$refs[this.template.currentAction].form
      let confirmationData = {
        title: 'Confirm deposit',
        modalType: 'confirm',
        waiting: false,
        pool: {
          from: {
            title: '',
            name: this.$store.state.pools.from.symbol,
            value: this.roundBigNumber(this.$store.state.pools.from.amount)
          },
          to: {
            title: '',
            name: this.$store.state.pools.to.symbol,
            value: this.roundBigNumber(this.$store.state.pools.to.amount)
          },
        },
        listData: [],
      }
      switch (this.template.currentAction) {
        case ADD_NEW_LIQUIDITY:
        case ADD_LIQUIDITY_TO_POOL:
          confirmationData.listData.push(
              {name: 'Share of pool', data: childForm.shareOfPool, dataText: '%'},
              {name: 'Pool tokens received', data: childForm.poolTokensReceived, dataText: ''},
          )
          break
        case WITHDRAW_FROM_POOL:
          confirmationData.title = 'Confirm withdraw'
          confirmationData.listData.push(
              {name: 'Pool tokens to handover', data: childForm.poolTokensHandOver, dataText: ''},
          )
      }

      return confirmationData
    },
    ...mapGetters([
      'isTokenHasId',
      'isTokensHasName',
      'getEtherscanTxLink',
      'isStepExists',
      'getStepsById'
    ])
  }
}
</script>

<style lang="scss">

  .tabs {
    margin-left: -3rem;
    margin-right: -3rem;

    .nav-tabs .nav-link {
      padding: 10px 16px;
    }
  }
</style>
